<template>
  <LayOut></LayOut>
</template>

<script>

window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}

import LayOut from './components/LayOut.vue'

export default {
  name: "App",
  components: {
    LayOut
  },
}
</script>


